import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import { colors } from '../components/colors';
import { Banner } from '../components/join/Banner';
import { messagesInitial } from '../components/join/message';
import SignupForm from '../components/join/SignupForm';
import Layout from '../components/layout';
import { notFoundStyles } from '../components/not_found';

import { newEvent } from '../utils/event';
import { merge } from '../utils/functional';
import { refreshSessionId } from '../utils/session_id';
import { messagesState, subscriptionTypeState } from '../utils/state';
import { StripeProviderComponent } from '../components/stripe/provider';

import * as aboutStyles from './about.module.css';

import { RenderHead } from "../components/head";



//
// configuration

const originType = process.env.ORIGIN_TYPE;
const memberContent = originType === 'private' || originType === 'expired' || originType === 'shifts-email-only';

const title = memberContent ? "You're already a Subscriber" : "Become a Subscriber";
export const Head = () => RenderHead({ title });




//
// public content

const PublicContent = () => {
    return (
        <div
            style={{
                margin: 0,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `flex-start`
            }} >

            <Banner />
            
            <StripeProviderComponent>
                <SignupForm />
            </StripeProviderComponent>
            
        </div>
    );
};


//
// private content

const PrivateContent = () => {
    return (
        <div
            style={{
                width: `100%`,
                overflow: `hidden`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `center`
            }} >

            <div
                className={ aboutStyles.container }
                style={{
                    margin: `150px 0`,
                    display: `flex`,
                    flexDirection: `column`,
                    justifyContent: `flex-start`,
                    alignItems: `center`
                }} >

                <div
                    style={ notFoundStyles.heading } >
                    You've already signed up!
                </div>

                <div
                    style={ merge(notFoundStyles.body, {
                        margin: `20px 0 0`,
                        textAlign: `center`
                    })
                          } >
                    
                    Go to your

                    <Link
                        to={ '/account' }
                        style={{
                            margin: '0 5px',
                            color: colors.green
                        }} >
                        Account
                    </Link>
                    
                    page to manage your subscription.
                    
                </div>

                <div
                    style={ merge( notFoundStyles.body, {
                        margin: `20px 0 0`,
                        textAlign: `center`
                    })} >
                    
                    <span>Browse 3 Shifts</span>

                    <Link
                        to={ '/search' }
                        style={{
                            margin: '0 5px',
                            color: colors.green
                        }} >
                        here
                    </Link>

                    <span>or contact us at</span>

                    <OutboundLink
                        style={{
                            margin: '0 5px',
                            color: colors.green
                        }}
                        href='mailto:help@6pages.com'>
                        help@6pages.com
                    </OutboundLink>

                    <span>if you need assistance.</span>
                    
                </div>
            </div>
        </div>
    );
};



//
// component

const Join = ({ data, location }) => {

    const [subscriptionType, setSubscriptionType] = useRecoilState( subscriptionTypeState );
    setSubscriptionType("SHIFTS_EMAIL_ONLY");
    const messageInitialByName = messagesInitial({ subscriptionType });
    const messageKeys = Object.keys(messageInitialByName);
    const setMessages = useSetRecoilState(messagesState(messageKeys));

    useEffect(() => {
        setMessages( messageKeys.map((k) => messageInitialByName[k]) );
    });
    
    useEffect(() => {
        newEvent({
            'level':           'trace',
            'event.namespace': 'web.newsubscriber',
            'event.name':      'loaded'
        });
    }, []);

    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
            location={ location }
            removeHorizontalPadding={ true } >
            
            { memberContent ? PrivateContent() : PublicContent({ subscriptionType }) }

        </Layout>
    );
};

export default Join;
